import { partnersStore } from '@/store'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import PartnerStatsButtons from './Buttons'
import './style.scoped.scss'

@Component
export default class PartnersStats extends tsx.Component<{}> {
  get totalPartners() {
    return partnersStore.partnersData!.total
  }
  get activePartners() {
    return partnersStore.partnersData!.active
  }
  get partnersWithPayments() {
    return partnersStore.partnersData!.withPayments
  }
  get activePercent() {
    return ((this.activePartners / this.totalPartners) * 100).toFixed()
  }
  get withPaymentsPercent() {
    return ((this.partnersWithPayments / this.totalPartners) * 100).toFixed()
  }

  protected render() {
    return (
      <b-card title="Статистика">
        <div class="stats-panel">
          <div class="stats-group">
            <div class="stats-field">
              <h3>{this.totalPartners}</h3>
              <small>Всего партнеров</small>
            </div>
            <div class="stats-field">
              <h3>{`${this.activePartners} (${this.activePercent}%)`}</h3>
              <small>Активных партнеров</small>
            </div>
            <div class="stats-field">
              <h3>{`${this.partnersWithPayments} (${this.withPaymentsPercent}%)`}</h3>
              <small>Партнеров с продажами</small>
            </div>
          </div>

          <div class="stats-group">
            <div class="stats-field">
              <h3>{partnersStore.ordersData?.count}</h3>
              <small>Курса продано</small>
            </div>
            <div class="stats-field">
              <h3>{`$${partnersStore.ordersData?.budget}`}</h3>
              <small>Бюджет сделок</small>
            </div>
            <div class="stats-field">
              <h3>{`$${partnersStore.ordersData?.paid}`}</h3>
              <small>Выплачено партнерам</small>
            </div>
          </div>

          <PartnerStatsButtons />
        </div>
      </b-card>
    )
  }
}
