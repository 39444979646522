import { BButton, BFormGroup } from '@/components/bootstrap'
import { Component, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import DateTimePicker from '@/components/controls/DateTimePicker'
import { showError, showMessage } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { partnersStore } from '@/store'
import PartnerCurrenciesModal from '../CurrenciesEditModal'
import './style.scoped.scss'

@Component
export default class PartnerStatsButtons extends tsx.Component<{}> {
  @Ref() readonly currenciesModal!: PartnerCurrenciesModal

  datePeriod: string = ''

  mounted() {
    if (
      partnersStore.paymentsData!.period.start &&
      partnersStore.paymentsData!.period.end
    ) {
      this.datePeriod = `${partnersStore.paymentsData!.period.start} — ${
        partnersStore.paymentsData!.period.end
      }`
    }
  }

  async updatePeriod() {
    try {
      const split = this.datePeriod.split('—')

      const start = split[0].trim()
      const end = split[1]?.trim() || start

      if (
        partnersStore.paymentsData!.period.start === start &&
        partnersStore.paymentsData!.period.end === end
      ) {
        return
      }

      await axios.post(`v1/partners/pay-period`, { start, end })

      partnersStore.paymentsData!.period.start = start
      partnersStore.paymentsData!.period.end = end

      showMessage('Период выплаты успешно обновлен')
    } catch (error) {
      console.error(error)
      showError('При обновлении дат произошла ошибка')
    }
  }

  protected render() {
    return (
      <div class="wrapper">
        <BFormGroup label="Следующая выплата">
          <DateTimePicker
            value={this.datePeriod}
            onInput={value => (this.datePeriod = value)}
            onChange={this.updatePeriod}
            dateFormat="Y-m-d H:i:S"
            displayedFormat="d.m.Y"
            range
          />
        </BFormGroup>

        <div class="buttons">
          <BButton variant={'primary'}>
            <feather-icon icon="RssIcon" />
          </BButton>
          <BButton
            onClick={() => this.currenciesModal.show()}
            variant={'outline-dark'}
          >
            $ ₴ ₽
          </BButton>
          {/* <BButton variant={'outline-dark'}>?</BButton> */}
          <BButton variant={'outline-dark'}>
            <feather-icon icon="MailIcon" />
          </BButton>
        </div>

        <PartnerCurrenciesModal ref="currenciesModal" />
      </div>
    )
  }
}
