import { Component, Watch, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import WayupTable from '@/components/WayupTable'
import { CellData, Column, FooterRow } from '@/components/WayupTable/types'
import { BCheckbox } from '@/components/bootstrap'
import axios from '@/libs/axios'
import { DateString } from '@/helpers/types/dateString'
import { Nullable } from '@/helpers/types/nullable'
import { AxiosResponse } from 'axios'
import UserCell from '@/components/WayupTable/cellTemplates/User'
import { showError } from '@/helpers/notifications'
import './style.scoped.scss'

interface IPartner {
  student: {
    id: number
    name: string
    avatar: string
    email: string
    phone: string
  }
  isBanned: boolean
  referralsCount: number
  budget: number
  coursesCount: number
  coursesToPaid: number
  accrued: number
  paidOut: number
  toPayoff: number
  rate: number
  lastPay: Nullable<DateString>
  registerDate: DateString
}

@Component
export default class PartnersTable extends tsx.Component<{}> {
  @Ref() readonly table!: WayupTable

  onlyNeedPay = false
  columns: Column<IPartner>[] = [
    {
      field: 'student',
      title: 'Партнер',
    },
    {
      field: 'isBanned',
      title: 'Статус',
      centered: true,
    },
    {
      field: 'referralsCount',
      title: 'Рефералов',
      centered: true,
    },
    {
      field: 'budget',
      title: 'Бюджет',
      centered: true,
      formatter: value => `$${value}`,
    },
    {
      field: 'coursesCount',
      title: 'Всего курсов',
      centered: true,
    },
    {
      field: 'coursesToPaid',
      title: 'Курсов к оплате',
      centered: true,
    },
    {
      field: 'accrued',
      title: 'Начислено',
      centered: true,
      formatter: value => `$${value}`,
    },
    {
      field: 'paidOut',
      title: 'Выплачено',
      centered: true,
      formatter: value => `$${value}`,
    },
    {
      field: 'toPayoff',
      title: 'К выплате',
      centered: true,
      formatter: value => `$${value}`,
    },
    {
      field: 'rate',
      title: 'Ставка',
      centered: true,
      formatter: value => `${value}%`,
    },
    {
      field: 'lastPay',
      title: 'Посл. выплата',
      centered: true,
      noWrap: true,
      formatter: value =>
        value
          ? new Date(value).toLocaleDateString('ru', {
              weekday: 'short',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })
          : '-',
    },
    {
      field: 'registerDate',
      title: 'Дата рег.',
      centered: true,
      noWrap: true,
      formatter: value =>
        new Date(value).toLocaleDateString('ru', {
          weekday: 'short',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }),
    },
  ]
  footerRow: FooterRow<IPartner> = {}

  @Watch('onlyNeedPay')
  onOnlyNeedPayChanged() {
    this.table.refresh()
  }

  async mounted() {
    try {
      const response: AxiosResponse<{
        referralsCount: number
        budget: number
        coursesCount: number
        coursesToPaid: number
        accrued: number
        paid: number
        paidOut: number
        toPayoff: number
      }> = await axios.get('v1/partners/summary')

      response.data.paidOut = response.data.paid

      this.footerRow = response.data

      await this.table.refresh()
    } catch (error) {
      console.error(error)
      showError('При загрузке данных произошла ошибка')
    }
  }

  async loadPartners(page: number, perPage: number, search: string) {
    const response: AxiosResponse<{
      partners: IPartner[]
      total: number
    }> = await axios.get(
      `v1/partners?limit=${perPage}&offset=${(page - 1) *
        perPage}&search=${search}&needPay=${this.onlyNeedPay ? 1 : 0}`,
    )

    return {
      rows: response.data.partners,
      totalRows: response.data.total,
    }
  }

  cellTemplate({ row, column, value }: CellData<IPartner>) {
    if (column.customField === 'buttons') {
      return
    }

    switch (column.field) {
      case 'student':
        return (
          <UserCell
            avatar={row.student.avatar}
            firstRow={row.student.name}
            secondRow={row.student.email}
          />
        )
      case 'isBanned':
        return <div class={['status', { banned: row.isBanned }]} />
    }
  }

  footerCellTemplate({ row, column, value }: CellData<IPartner>) {
    if (column.customField === 'buttons') {
      return
    }

    switch (column.field) {
      case 'student':
        return 'Всего:'
      case 'rate':
      case 'registerDate':
      case 'lastPay':
        return <div></div>
    }
  }

  protected render() {
    return (
      <b-card no-body>
        <b-card-body>
          <div class="d-flex align-items-center">
            <h4 class="m-0">Партнеры</h4>
            <BCheckbox
              checked={this.onlyNeedPay}
              onInput={value => {
                this.onlyNeedPay = value
              }}
              class="ml-auto"
            >
              Только те, кому нужно выплатить
            </BCheckbox>
          </div>
        </b-card-body>
        <WayupTable
          ref="table"
          columns={this.columns}
          fetchFunction={this.loadPartners}
          footerRow={this.footerRow}
          searchEnabled
          paginationEnabled
          fetchOnMount={false}
          scopedSlots={{
            default: cellData => this.cellTemplate(cellData),
            footer: cellData => this.footerCellTemplate(cellData),
          }}
        />
      </b-card>
    )
  }
}
