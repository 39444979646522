import { Component, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BModal, BInput, BOverlay } from '@/components/bootstrap'
import Switcher from '@/components/controls/Switcher'
import './style.scoped.scss'
import { partnersStore } from '@/store'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'

@Component
export default class PartnerCurrenciesModal extends tsx.Component<{
  show?: () => void
}> {
  modalId = 'partner-currencies-modal'

  initialized = false
  pending = false

  useActual = false

  rub = 0
  uah = 0
  points = 0

  get currenciesData() {
    return partnersStore.currenciesData!
  }

  @Watch('useActual')
  async onUseActualChanged(useActual: boolean) {
    if (await this.updateValues({ useActual })) {
      this.rub = this.useActual
        ? this.currenciesData.rub
        : this.currenciesData.custom.rub

      this.uah = this.useActual
        ? this.currenciesData.uah
        : this.currenciesData.custom.uah

      this.currenciesData.useActual = useActual
    }
  }

  async show() {
    this.useActual = this.currenciesData.useActual

    this.rub = this.useActual
      ? this.currenciesData.rub
      : this.currenciesData.custom.rub

    this.uah = this.useActual
      ? this.currenciesData.uah
      : this.currenciesData.custom.uah

    this.points = this.currenciesData.custom.points

    this.$bvModal.show(this.modalId)

    this.$nextTick(() => (this.initialized = true))
  }

  async updateUah() {
    if (this.uah === this.currenciesData.custom.uah) {
      return
    }

    if (await this.updateValues({ uah: this.uah })) {
      this.currenciesData.custom.uah = this.uah
    }
  }

  async updatRub() {
    if (this.rub === this.currenciesData.custom.rub) {
      return
    }

    if (await this.updateValues({ rub: this.rub })) {
      this.currenciesData.custom.rub = this.rub
    }
  }

  async updatPoints() {
    if (this.points === this.currenciesData.custom.points) {
      return
    }

    if (await this.updateValues({ points: this.points })) {
      this.currenciesData.custom.points = this.points
    }
  }

  async updateValues(data: {
    uah?: number
    rub?: number
    points?: number
    useActual?: boolean
  }) {
    if (!this.initialized) {
      return
    }

    try {
      this.pending = true

      await axios.post('v1/partners/currencies', {
        uah: data.uah ?? this.currenciesData.custom.uah,
        rub: data.rub ?? this.currenciesData.custom.rub,
        points: data.points ?? this.currenciesData.custom.points,
        useActual: data.useActual ?? this.currenciesData.useActual,
      })

      this.pending = false
      return true
    } catch (error) {
      console.error(error)
      showError('При обновлении курсов произошла ошибка')
      this.pending = false
      return false
    }
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        centered
        title="Курс валют"
        hide-footer
        busy={this.pending}
        onHidden={() => (this.initialized = false)}
      >
        <BOverlay show={this.pending} no-wrap />

        <div class="d-flex align-items-center mb-1">
          <span onClick={() => (this.useActual = false)} class="cursor-pointer">
            Кастомные
          </span>
          <Switcher
            value={this.useActual}
            onInput={value => (this.useActual = value)}
            class="ml-50"
          />
          <span onClick={() => (this.useActual = true)} class="cursor-pointer">
            Актуальные
          </span>
        </div>
        <div class="d-flex mb-1">
          <div class="d-flex align-items-center mr-2">
            ₴
            <BInput
              value={this.uah}
              onInput={value => (this.uah = value)}
              onBlur={this.updateUah}
              onKeydown={e => {
                if (e.key === 'Enter') {
                  this.updateUah()
                }
              }}
              type="number"
              number
              disabled={this.useActual}
              class="currency-input mx-50"
            />
            /$
          </div>
          <div class="d-flex align-items-center mr-2">
            ₽
            <BInput
              value={this.rub}
              onInput={value => (this.rub = value)}
              onBlur={this.updatRub}
              onKeydown={e => {
                if (e.key === 'Enter') {
                  this.updatRub()
                }
              }}
              type="number"
              number
              disabled={this.useActual}
              class="currency-input mx-50"
            />
            /$
          </div>
          <div class="d-flex align-items-center">
            <img src={require(`@/assets/images/icons/points.svg`)} alt="" />
            <BInput
              value={this.points}
              onInput={value => (this.points = value)}
              onBlur={this.updatPoints}
              onKeydown={e => {
                if (e.key === 'Enter') {
                  this.updatPoints()
                }
              }}
              type="number"
              number
              class="currency-input mx-50"
            />
            /$
          </div>
        </div>
      </BModal>
    )
  }
}
