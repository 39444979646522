import PartnersTable from '@/components/partners/PartnersTable'
import PartnersStats from '@/components/partners/Stats'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class PartnersPage extends Vue {
  protected render() {
    return (
      <div>
        <PartnersStats />
        <PartnersTable />
      </div>
    )
  }
}
