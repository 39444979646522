export function valueFromPath(obj: Record<string, any>, path: string) {
  if (!path) {
    throw new Error('path must be a string')
  }

  const split = path.split('.')

  let value = obj[split.shift()!]

  for (const el of split) {
    value = value[el]
  }

  return value
}
